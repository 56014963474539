import React from "react";
import LoginButton from '../Auth/LoginButton';
import Dashboard from '../Dashboard/Dashboard';
import styled from 'styled-components';
import {isLoggedIn} from "../Auth/AuthProvider";

const HomeContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #f0f4f8;
    padding: 20px;
`;

const LogoImage = styled.img`
    width: ${(props) => (props.isLoggedIn ? '20%' : '30%')}; // Reduce size for better look when not logged in
    margin-bottom: ${(props) => (props.isLoggedIn ? '1rem' : '3rem')}; // Adjust margin for logged-in state
    transition: width 0.3s ease-in-out, margin-bottom 0.3s ease-in-out; // Smooth transition for logo size change
`;

const LoginContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ffffff;
    padding: 20px 40px;
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); // Subtle shadow for emphasis
`;

const LoginText = styled.p`
    font-size: 1.2rem;
    color: #333; // Darker text color for better readability
    margin-bottom: 1.5rem;
    text-align: center;
    letter-spacing: 0.5px; // Slight letter spacing for cleaner look
    line-height: 1.6;
`;

const HomePage = () => {
    const isAuthenticated = isLoggedIn();

    return (
        isAuthenticated ? <Dashboard/> :
            <HomeContainer>
                <LogoImage
                    src={`${process.env.PUBLIC_URL}/logo-with-name-and-slogan-STATIC.svg`}
                    alt="Logo with Name and Slogan"
                    isLoggedIn={isAuthenticated.toString()}  // Pass login state as prop to change logo size
                />
                <LoginContainer>
                    <LoginText>
                        Please log in to access your media dashboard and upload your content.
                    </LoginText>
                    <LoginButton/>
                </LoginContainer>
            </HomeContainer>
    );
};

export default HomePage;
