import {getWithAuth} from "../Network/HttpMethods";
import MediaRendererComponent from "./MediaRendererComponent";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {HOST_URL} from "../../config/config";
import styled from "styled-components";

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background-color: #f4f4f4;
`;
const MediaContainer = styled.div`
    max-width: 90vw;
    max-height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
`;
export const ContentWrapperComponent = () => {
    const {type, id} = useParams();
    const [fileData, setFileData] = useState(null);

    useEffect(() => {
        async function fetchFileData() {
            try {
                let path;
                if (type === 'files') {
                    path = `/meta/files/${id}`;
                } else if (type === 'links') {
                    path = `/meta/files/links/${id}`;
                } else if (type === 'public_links') {
                    path = `/public/meta/files/links/${id}`;
                }
                else {
                    throw new Error("Invalid specifier.");
                }

                const endpoint = `${HOST_URL}${path}`;
                const response = await getWithAuth(endpoint);
                const data = await response.json();
                setFileData(data);
            } catch (error) {
                console.error(error);
            }
        }

        fetchFileData();
    }, [type, id]);

    if (!fileData) return <p>Loading...</p>;

    return (
        <Wrapper>
            <MediaContainer>
                <MediaRendererComponent file={fileData} type={type}/>
            </MediaContainer>
        </Wrapper>);
}