import React, {useEffect, useState} from "react";
import {getWithAuth} from "../Network/HttpMethods";

const ImageSteamRenderer = ({url, fileMeta}) => {
    const [mediaSrc, setMediaSrc] = useState(null);

    useEffect(() => {
        async function fetchMedia() {
            try {
                const response = await getWithAuth(url);
                const blob = await response.blob();
                setMediaSrc(URL.createObjectURL(blob));
            } catch (error) {
                console.error(error);
            }
        }

        fetchMedia();
    }, [url, fileMeta.id, fileMeta.type]);

    if (!mediaSrc) {
        return <p>Loading media...</p>
    }
    return (
        <img
            src={mediaSrc}
            alt={fileMeta.name}
            style={{width: '500px', objectFit: 'cover', borderRadius: '4px', marginRight: '10px'}}/>)

};

export default ImageSteamRenderer;
