import styled from "styled-components";

export const MediaList = styled.ul`
  list-style-type: none;
  padding-left: 0;
  margin-top: 20px;
`;

export const MediaItemInfo = styled.span`
    margin: 0 16px;
`
export const MediaItem = styled.li`
  background-color: #fff;
  margin-bottom: 10px;
  padding: 15px;
  border-radius: 6px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
  font-size: 1rem;
  color: #555;

  @media (max-width: 768px) {
    padding: 12px;
    font-size: 0.9rem;
  }
`;

export const GenerateLinkButton = styled.button`
    margin-left: 10px;
    margin-right: 10px;
    background-color: #689eda;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
        background-color: #4976a8;
    }
`;
export const DeleteButton = styled.button`
    margin-left: 10px;
    margin-right: 10px;
    padding: 5px 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
        background-color: #0056b3;
    }
`;

export default MediaList;