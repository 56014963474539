import styled from "styled-components";

export const RedButton = styled.button`
  padding: 12px 24px;
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  margin-top: 10px;

  &:hover {
    background-color: #d32f2f;
  }
`;

export const EmptyRedButton = styled.button`
    background-color: #fa5852;
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    margin-top: 10px;

    &:hover {
        background-color: #d32f2f;
    }
`;

export const GreenButton = styled.button`
    padding: 12px 24px;
    background-color: #4eb84f;
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    margin-top: 10px;

    &:hover {
        background-color: #47a548;
    }
`;