import React, { useState } from "react";
import styled from "styled-components";
import postWithAuth from "../Network/HttpMethods";
import {useDropzone} from "react-dropzone";

const UploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  padding: 20px 40px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
`;

const UploadButton = styled.button`
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 10px;

  &:hover {
    background-color: #0056b3;
  }
`;

const DropzoneArea = styled.div`
    width: 100%;
    padding: 20px;
    border: 2px dashed #007bff;
    border-radius: 8px;
    background-color: #f8f9fa;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #e9ecef;
    }
`;

const UploadStatus = styled.p`
  font-size: 1rem;
  color: ${(props) => (props.success ? "green" : "red")};
  margin-top: 10px;
`;

const StyledFileInput = ({ uploadUrl, setUploadedFiles }) => {
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [uploadStatus, setUploadStatus] = useState("");

    const onDrop = (acceptedFiles) => {
        setSelectedFiles(acceptedFiles);
        setUploadStatus(""); // Reset status on new selection
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    const handleUpload = async () => {
        if (selectedFiles.length === 0) {
            setUploadStatus("Please select or drop files first.");
            return;
        }

        const formData = new FormData();
        selectedFiles.forEach((file) => formData.append("file", file));

        try {
            setUploadStatus("Uploading...");
            const response = await postWithAuth(uploadUrl, formData);

            if (response.ok) {
                setUploadStatus("Files uploaded successfully!");
                const uploadedFiles = selectedFiles.map((file) => ({
                    name: file.name,
                    size: file.size,
                }));
                setUploadedFiles(uploadedFiles); // Notify Dashboard of uploaded files
            } else {
                const errorText = await response.text();
                setUploadStatus(`Upload failed: ${errorText}`);
            }
        } catch (error) {
            console.error("Upload error:", error);
            setUploadStatus("An error occurred during the upload.");
        }
    };

    return (
        <UploadContainer>
            <DropzoneArea {...getRootProps()}>
                <input {...getInputProps()} />
                {isDragActive ? (
                    <p>Drop the files here...</p>
                ) : (
                    <p>
                        Drag & drop some files here, or click to select files.
                    </p>
                )}
            </DropzoneArea>
            {selectedFiles.length > 0 && (
                <p>
                    Selected Files: {selectedFiles.map((file) => file.name).join(", ")}
                </p>
            )}
            <UploadButton onClick={handleUpload}>Upload</UploadButton>
            {uploadStatus && (
                <UploadStatus success={uploadStatus.includes("successfully")}>
                    {uploadStatus}
                </UploadStatus>
            )}
        </UploadContainer>
    );
};

export default StyledFileInput;
