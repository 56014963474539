import {HOST_URL} from '../../config/config';
import VideoStreamRenderer from "./VideoStreamRendererComponent";
import ImageSteamRenderer from "./ImageStreamRendererComponent";

const MediaRendererComponent = ( { file, type }) => {
    let path;

    if (type === 'files') {
        path = `/files/${file.id}`;
    } else if (type === 'links') {
        path = `/files/links/${file.id}`;
    } else if (type === 'public_links') {
        path = `/public/files/links/${file.id}`;
    }
    else {
        throw new Error("Invalid specifier.");
    }

    if (file.contentType.startsWith('image/')) {
        return (
            <ImageSteamRenderer
                url={`${HOST_URL}${path}`}
                fileMeta={file}
            />
        );
    }

    if (file.contentType.startsWith('video/')) {
        return (
            <VideoStreamRenderer
                url={`${HOST_URL}${path}`}
                fileMeta={file}
            />
        )
    }
}

export default MediaRendererComponent;
