import React from 'react';

import {RedButton} from '../Shared/RedButton';
import { logout } from './AuthProvider';



const LogoutButton = () => {
  return (
    <RedButton onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>
      Logout
    </RedButton>
  );
};

export default LogoutButton;
