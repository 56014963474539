import { getWithAuth } from "../Network/HttpMethods";
import React, {useEffect, useState} from "react";

function VideoStreamRenderer({ url, fileMeta }) {
    const [mediaSrc, setMediaSrc] = useState(null);

    useEffect(() => {
        async function fetchMedia() {
            try {
                const response = await getWithAuth(url);
                const blob = await response.blob();
                setMediaSrc(URL.createObjectURL(blob));
            } catch (error) {
                console.error(error);
            }
        }

        fetchMedia();
    }, [url, fileMeta.id, fileMeta.type]);

    if (!mediaSrc) {
        return <p>Loading media...</p>
    }
    return (
        <video width="500" height="500" controls>
            <source src={mediaSrc} type={fileMeta.contentType}/>
        </video>
    )

    // const containerRef = useRef(null);
    // let isFetching = false;
    //
    // useEffect(() => {
    //     if (!fileMeta || !fileMeta.id || !containerRef.current) return;
    //
    //     let videoElement = document.createElement("video");
    //     videoElement.controls = true;
    //     videoElement.width = 640;
    //     videoElement.height = 360;
    //
    //     let mediaSource = new MediaSource();
    //     videoElement.src = URL.createObjectURL(mediaSource);
    //     containerRef.current.appendChild(videoElement);
    //
    //     mediaSource.addEventListener("sourceopen", function () {
    //         if (mediaSource.readyState !== "open") return;
    //
    //         let typeAndCodec = fileMeta.contentType;
    //         if (typeAndCodec === "video/mp4") {
    //             typeAndCodec = 'video/mp4; codecs="avc1.42E01E, mp4a.40.2"';
    //         }
    //
    //         let sourceBuffer = mediaSource.addSourceBuffer(typeAndCodec);
    //         let rangeStart = 0;
    //         const chunkSize = 1024 * 1024; // 1MB chunks
    //         let isFirstChunk = true;
    //
    //         function fetchChunk() {
    //             if (!sourceBuffer || mediaSource.readyState !== "open" || sourceBuffer.updating || isFetching) return;
    //             isFetching = true;
    //
    //             const rangeHeader = `${rangeStart}-${rangeStart + chunkSize - 1}`;
    //             getWithAuth(url, { headers: { Range: rangeHeader } })
    //                 .then(response => {
    //                     if (!response.ok) throw new Error("Failed to fetch video chunk.");
    //                     return response.arrayBuffer();
    //                 })
    //                 .then(arrayBuffer => {
    //                     if (mediaSource.readyState !== "open") return;
    //                     sourceBuffer.appendBuffer(arrayBuffer);
    //                 })
    //                 .catch(error => console.error("Error fetching video chunk:", error));
    //         }
    //
    //         function handleUpdateEnd() {
    //             if (rangeStart < fileMeta.size) {
    //                 rangeStart += chunkSize;
    //                 isFetching = false; // Reset only after successful update
    //                 fetchChunk();
    //             } else {
    //                 if (!sourceBuffer.updating && mediaSource.readyState === "open") {
    //                     mediaSource.endOfStream();
    //                 }
    //             }
    //             if (isFirstChunk && videoElement.paused) {
    //                 videoElement.play().catch(error => console.warn("Autoplay prevented:", error));
    //                 isFirstChunk = false;
    //             }
    //         }
    //
    //         sourceBuffer.addEventListener("updateend", handleUpdateEnd);
    //         fetchChunk();
    //     });
    //
    //     return () => {
    //         if (containerRef.current && videoElement) {
    //             containerRef.current.removeChild(videoElement);
    //         }
    //     };
    // }, [url, fileMeta]);
    //
    // return <div ref={containerRef}></div>;
}

export default VideoStreamRenderer;
