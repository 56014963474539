import {LOGIN_BASE_URL} from "../../config/config";
import {postWithAuth, postWithoutAuth} from "../Network/HttpMethods";


export const AuthProvider = (props) => {
    return (<div>{props.children}</div>);
};

export const HandleRedirect = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const idToken = searchParams.get("id_token");
    const refreshToken = searchParams.get("refresh_token");

    setIdToken(idToken);
    setRefreshToken(refreshToken);

    if (isLoggedIn()) {
        const redirectPath = localStorage.getItem('redirect_after_login') + "";
        localStorage.removeItem('redirect_after_login');

        if (redirectPath) {
            window.location.href = window.location.origin + redirectPath;
        } else {
            window.location.href = window.location.origin + "/dashboard";
        }

    } else {
        window.location.href = LOGIN_BASE_URL + "/login?redirect_uri=" + window.location.origin + "/callback";
    }
}
export const parseToken = (token) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};

const getRefreshToken = () => localStorage.getItem('refresh_token');
const tokenIsExpired = (getTokenFunc) => parseToken(getTokenFunc()).exp * 1000 <= Date.now();
const setIdToken = (idToken) => localStorage.setItem('id_token', idToken);
const setRefreshToken = (refreshToken) => localStorage.setItem('refresh_token', refreshToken);

export const refreshAuthentication = async () => {
    const refreshToken = getRefreshToken();
    if (!!refreshToken) {
        try {
            const response = await postWithoutAuth(`${LOGIN_BASE_URL}/refresh`,{
                refresh_token: refreshToken });
            const { id_token, refresh_token } = response.body;
            setIdToken(id_token);
            setRefreshToken(refresh_token);
        } catch (error) {
            console.error(error);
            logout();
        }
    } else {
        logout();
    }
}
export const getIdToken = () => localStorage.getItem('id_token');
export const idTokenExpired = () => tokenIsExpired(getIdToken);
export const isLoggedIn = () => !!getIdToken();
export const logout = () => {
    localStorage.setItem('redirect_after_login', window.location.pathname + window.location.search);
    localStorage.removeItem('id_token');
    localStorage.removeItem('refresh_token');
    window.location.href = window.location.origin;
}

export default AuthProvider;