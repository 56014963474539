import {getIdToken, idTokenExpired, isLoggedIn, refreshAuthentication} from "../Auth/AuthProvider";
export const postWithoutAuth = async (url, body) => {
    console.log(body);
    return fetch(url, {
        method: "POST",
        body: JSON.stringify(body),
    });
}
export const postWithAuth = async (url, body, options = {}) => {
    if (!body) {
        body = null;
    }
    return fetch(url, {
        method: "POST",
        headers: await headers(options),
        body: body,
        ...options,
    });
};
const postFileWithAuth = async (url, body, options = {}) => {

    if (!(body instanceof FormData)) {
        throw new Error("Body must be a FormData instance for multipart/form-data requests.");
    }

    return postWithAuth(url, body, options);
};

export const getWithAuth = async (url, options = {}) => {
    return fetch(url, {
        method: "GET",
        headers: await headers(options),
    });

}

const headers = async (options = {}) => {
    if (isLoggedIn() && idTokenExpired()) {
        await refreshAuthentication();
    }

    let myHeaders = {};
    if ("headers" in options) {
        myHeaders = {...(options.headers)};
    }

    const token = getIdToken();

    if (token) {
        myHeaders.Authorization = `Bearer ${token}`;
    }
    return myHeaders;
}

export default postFileWithAuth;
